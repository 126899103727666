body {
	font-family: 'Montserrat', Arial, sans-serif !important;
	background: #E7E8E9;
}
.navbar-main {
	background-color: #252525;
	box-shadow: 0 2px 1px #1d75bb;
}
.main-header-logo {
	width: 80px;
}
.mobile-header-logo {
	position: relative;
	right: 50%;
	left: 50%;
	top: 10px;
}
/* .mobile-header-logo img {
	margin: 0 auto;
	position: relative;
	left: 50%;
	top: -10px;
} */
.main-nav-spacer {
	margin-left: -4px;
}
.pagination {
	margin-top: 0 !important;
}
.main-navigation {
	position: relative;
}
.main-menu-drop:hover > .dropdown-menu {
	display: block;
}
#search {
	display: none;
	position: absolute;
	top: 4px;
    /* left: 466px; */
    left: 490px;
    width: 60px;
    height: 60px;
}
#search.focus {
	display: block;
	z-index: 20;
}
#input {
	display: none;
    position: absolute;
    top: 0;
    left: 60px;
    width: 450px;
    height: 62px;
    z-index: 5;
    overflow: hidden;
}
#input input {
    display: block;
    position: absolute;
    top: 0;
    left: 450px;
    width: 450px;
    height: 100%;
    margin: 0;
    padding: 0 10px;
    border: none;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 18px;
    backface-visibility: none;
    border-radius: 0;
    transition: left 0;
}
#input input:focus {
    outline: none
}
#input.focus {
	display: block;
    z-index: 20
}
#input.focus input {
    left: 0;
    transition: left 0.3s;
}
.mobile-header-logo img {
	width: 80px;
}
.main-footer-logo {
	width: 150px;
}
.navbar-main>ul {
	margin: auto;
	paddding: 0;
	display: block;
	width: 1170px;
	/* this will need to be adjusted according to the design */
}
.navbar-main>ul>li {
	display: inline-block;
	vertical-align: middle;
}
.menu-icon-text {
	display: block;
	text-align: center;
	font-size: 8pt;
}
.navbar-border {
	    border-right: 1px solid #7b7b7b;
}
.navbar-main>ul>li>a {
	color: #D3D3D4;
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 10pt;
}
.navbar-main>ul>li>a:hover {
	background-color: #0073BB;
}
.navbar-main>ul>li:nth-child(-n+2) a:hover, a:focus {
	color: #FFFFFF;
	background-color: inherit;
}
.page-header {
	font-size: 12pt;
	text-transform: uppercase;
	font-family: 'Montserrat', Arial, sans-serif;
}
.img-hover-glow:hover {
	filter: saturate(10%);
}
.main-container {
	margin-top: 10px;
	min-height: 600px;
}
.menu-social hr {
	margin-right: 10px;
}
.menu-social {
	padding-left: 10px;
}
.menu-social li {
	display: inline;
	list-style: none;
	margin-right: 20px;
}
.menu-resources {
	font-family: 'Montserrat', Arial, sans-serif;
	padding-left: 10px;
}
.menu-resources a {
	font-size: 11pt;
	color: #FFFFFF;
}
.menu-links hr {
	margin-right: 10px;
}
.menu-links {
	padding-left: 10px;
}
.menu-links li {
	font-family: 'Montserrat', Arial, sans-serif;
	padding: 15px 0;
}
.menu-links li a {
	font-size: 11pt;
	color: #FFFFFF;
}
/* article styles */
.articles-more {
	margin-top: 0;
	padding-bottom: 5px;
	border-bottom: 1px solid #CDCFD0;
	text-align: right;
	padding-right: 5px;
}
/* obituary styles */
.obit-container {
	background-image: url('../images/obituary/Obit-background.jpg');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.obit-image {
	max-height: 100%;
	max-width: 100%;
	text-align: left;
	padding: 0 0;
}

.obit-image img {
	max-height: 190px;
}
.obit-name {
	margin-top: 5px;
	margin-bottom: 0;
	font-family: 'Montserrat', Arial, sans-serif;
	font-weight: bold;
}
.obit-border {
	    border-bottom: 2px solid #959595;
    padding: 15px 0;
    width: 100%;
}
.obit-publication {
	font-family: 'Montserrat Regular', Arial, sans-serif;
	font-size: 8pt;
	font-style: italic;
	margin: 0 0 20px 0;
}
/* end of obituary styles */

/* footer styles */
.footer-resources {
	background-color: #0373BB;
	text-align: center;
}
.footer-logos {
	margin: 14px 0;
}
.footer-links {
	background-color: #252525;
	padding: 40px 0;
}
.footer-resources p a, .footer-resources p span {
	color: #FFFFFF;
	font-family: 'Montserrat';
}
.footer-social {
	padding-left: 0;
}
.footer-social li {
	display: inline;
	margin-right: 32px;
	list-style: none;
}
.footer-copyright {
	font-size: 8pt;
	color: #FFFFFF;
	margin-left: 5px;
}
.footer-nav {
	padding-left: 0;
}
.footer-nav li:nth-child(1) {
	list-style: none;
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 11pt;
}
.footer-nav li:nth-child(1) a {
	color: #C7D1E3;
}
.footer-nav li {
	list-style: none;
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 8pt;
	padding: 2px 0;
}
.footer-nav li a {
	color: #FFFFFF;
}
/* end of footer styles */

.advertise-table-large {
	width: 100%;
	background-image: url('/images/advertise/PuzzleLayers.png');
	background-repeat: no-repeat;
	background-position: 350px 20px;
}

.advertise-top-text {
	font-size: 38pt;
	font-family: times, serif;
	color: #6B6B70;
	text-shadow: -2px -2px 5px #FFF, 2px -2px 5px #FFF, -2px 2px 5px #FFF, 2px 2px 5px #FFF;
}

.advertise-logo-text {
	width: 255px;
	font-size: 15pt;
	color: #6B6B70;
	text-align: center;
	font-family: times, serif;
}

.advertise-puzzle-text {
	font-size: 30pt;
	color: #6B6B70;
	font-family: times, serif;
	padding-top: 200px;
	padding-left: 20px;
}
.advertise-puzzle-text-mobile {
	font-size: 20pt;
	color: #6B6B70;
	font-family: times, serif;
}

.advertise-puzzle-text-color-mobile {
	font-size: 22pt;
	color: #0073BA;
	text-shadow: -2px -2px 5px #FFF, 2px -2px 5px #FFF, -2px 2px 5px #FFF, 2px 2px 5px #FFF;
}

.advertise-puzzle-text-color {
	font-size: 32pt;
	color: #0073BA;
	text-shadow: -2px -2px 5px #FFF, 2px -2px 5px #FFF, -2px 2px 5px #FFF, 2px 2px 5px #FFF;
}
/* article styles */
.article-body-text, .webpage-body-text {
	font-family: 'Helvetica', Arial, sans-serif;
	font-weight: normal;
}

/* pull quote sytles*/
.article-pull-quote {
	border-left: 5px solid #0373BA;
}
/* end of pull quote sytles*/

/* reporter bios styles */
.article-reporter-bio-container {
	border: 1px solid #000000;
}

/* end of reporter bios styles */



/* end of article styles */

/* global styles */
.set-font-family {
	font-family: 'Montserrat', Arial, sans-serif;
}
.clickable {
	cursor: pointer;
}
.text-white {
	color: #FFFFFF;
}
.text-blue {
	color: #0373BA;
}
.text-blue-bold {
	color: #0373BA;
	font-weight: bold;
}
.text-right {
	text-align: right;
}
.reset-margins {
	margin: 0 auto;
}
.text-center {
	text-align: center;
}
.margin-top-five {
	margin-top: 5px;
}
.margin-top-ten {
	margin-top: 10px;
}
.article-standard-max-width {
	max-width: 300px;
}
.article-mugshot-max-width {
	max-width: 175px;
}
.article-content-width {
	width: 300px !important;
}
.margin-top {
	margin-top: 20px;
}
.margin-bottom {
	margin-bottom: 20px;
}
.margin-bottom-five {
	margin-bottom: 5px;
}
.margin-bottom-ten {
	margin-bottom: 10px;
}
.margin-left {
	margin-left: 10px;
}
.margin-right {
	margin-right: 10px;
}
.margin-five {
	margin: 5px;
}
.margin-ten {
	margin: 10px;
}
.margin-right-twenty {
	margin-right: 20px !important;
}
.margin-right-five {
	margin-right: 5px;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.no-margin-top {
	margin-top: 0;
}
.no-margin-left {
	margin-left: 0;
}
.padding-five {
	padding: 5px;
}
.padding-ten {
	padding: 10px;
}
.padding-bottom {
	padding-bottom: 10px;
}
.padding-bottom-fifteen {
	padding-bottom: 15px;
}
.padding-right-ten {
	padding-right: 10px;
}
.padding-top-fifteen {
	padding-top: 15px;
}
.padding-top {
	padding-top: 10px;
}
.no-padding-right {
	padding-right: 0 !important;
}
.no-padding-left {
	padding-left: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}
.no-padding-top {
	padding-top: 0 !important;
}
.padding-top-bottom-five {
	padding: 5px 0;
}
.padding-left-fifteen {
	padding-left: 15px !important;
}
.padding-left-ten {
	padding-left: 8px;
}
.dark-bg {
	background-color: #252525;
}
.line-height {
	line-height: 1.1;
}
.line-height-sm {
	line-height: .9;
}
.text-underline {
	text-decoration: underline;
}
.p-xs {
	font-size: 8pt;
}
.p-small {
	font-size: 9pt;
}
.p-medium {
	font-size: 12pt;
}
.p-large {
	font-size: 14pt;
}
.p-xlarge {
	font-size: 16pt;
}
.p-super {
	font-size: 2em;
}
.border-muted-bottom {
	border-bottom: 2px solid #CDCFD0;
	padding-bottom: 15px;
}
.border-right-muted {
	border-right: 1px solid #A8AAAB;
}
.border-dark-bottom {
	border-bottom: 2px solid #717171;
	padding-bottom: 15px;
}
.min-height-onehundred {
	min-height: 100px;
}
.border-dark-top {
	border-top: 2px solid #717171;
	padding-top: 15px;
}
.border-dark-bottom-with-padding {
	border-bottom: 2px solid #717171;
}
.one-hundred-percent-width {
	width: 100%;
}
.text-muted-bold {
	color: #A8AAAB;
	font-weight: bold;
}
.text-muted {
	color: #A8AAAB;
}
.text-muted-red {
	color: #D20000;
	font-weight: bold;
}
.link-text-black {
	color: #000000;
}
.no-hover:hover {
	text-decoration: none;
}
.text-small {
	font-size: 9pt;
}
.text-cutline-color {
	font-weight: bold;
	color: #808282;
}
.border-gray {
	border: 2px solid #D1D3D4;
}
.text-publication-color {
	color: #808282;
	font-size: 10pt;
}
.text-category-box {
	display: block;
	color: #808282;
	font-size: 10pt;
	height: 20px;
}
.leadin-text > p {
	margin-bottom: 0;
}
.img-small {
	width: 100px;
}
.img-medium {
	width: 150px;
}
.img-large {
	width: 300px !important;
}
.text-danger {
	color: #FF2C21;
}
.text-success {
	color: #85CE4C;
}
.float-left {
	display: block;
	float: left;
	clear: both;
}
.float-right {
	display: block;
	float: right;
	clear: both;
}
.text-normal {
	text-transform: none !important;
}
.thirty-width {
	width: 30px;
}
.box-shadow {
	-webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
	box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
}

/* img floats styles with text */
.img-xs-float-left {
	float:left; 
	width: 80px;
}
.img-xxs-float-left {
	float:left; 
	width: 15px;
}
.img-sm-float-left {
	display: table-cell;
	float: left; 
	/* width: 90px; */
	width: 100px;
	clear: both;
}
.img-sm-bio-float-left {
	display: table-cell;
	float: left; 
	/* width: 90px; */
	width: 95px;
	clear: both;
}
.img-md-float-left {
	display: table-cell;
	float: left; 
	/* width: 90px; */
	width: 130px;
	clear: both;
}
.text-xs-float-right {
	display: inline-block; 
	width: 130px;
}
.text-xs-float-right-trending {
	display: inline-block; 
	width: 170px;
}
.text-sm-float-right {
	display: inline-block; 
	width: 140px;
}
.text-md-float-right {
	display: inline-block; 
	width: 175px;
}
.text-md-float-right-sds-events {
	display: inline-block; 
	width: 145px;
}
.text-full-width-float-right {
	display: inline-block; 
	width: 80%;
}
.img-overlay-top-left {
	position: absolute;
	z-index: 2;
	left: 0px;
	top: -5px;
}
.align-middle {
	vertical-align: middle;
}
.multiline-cell {
	white-space: pre-wrap;
	word-wrap: break-word;
}
div.errors, div.successes {
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
}
div.errors {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}
div.successes {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}
div.errors ul, div.successes ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
label.required::after {
	content: "*";
	color: #a94442;
}
.list-unstyled {
	list-style: none;
	padding-left: 0;
}
.flex-slide-img {
	display: block;
	width: 100% !important;
	height: 600px !important;
}
/* .sidebar-fixed {
	position: fixed;
	top: 51px;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: block;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #f5f5f5;
	border-right: 1px solid #eeeeee;
} */
/* end of global styles */

/* classifieds listing styles */
.classified-list-container {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/* min-height: 200px; */
	min-height: 320px;
}
.classified-title {
	margin-top: 5px;
	margin-bottom: 0;
	font-family: 'Montserrat', Arial, sans-serif;
}
.classifieds-image-overflow {
	overflow-y: hidden;
	max-height: 160px;
}
.classified-created {
	font-family: 'Montserrat Regular', Arial, sans-serif;
	font-size: 8pt;
	font-style: italic;
}
.classified-preview-image {
	text-align: center;
	padding: 15px 0;
}
.classified-preview-image > img {
	width: 100%;
}
.classified-ad-preview-container {
	background-color: #FFFFFF;
}
.classified-image {
	/* height: 180px; */
	text-align: center;
}
.classified-image-detail-preview {
	width: 100px;
}
.image-dropzone {
	border: 1px dashed #DDDDDD;
	background-color: #F8F8F8;
	border-radius: 5px;
	text-align: center;
	margin-bottom: 15px;
	padding: 25px;
	cursor: pointer;
}
/* .classified-email-recaptcha > div.g-recaptcha {
	float: right;
} */
/* end of classifieds listing styles */

/* event page styles */
.event-time-box {
	float:left; 
	width: 80px;
	height: 30px;
	background-color: #363636;
	color: #FFFFFF;
}
.event-date-box {
	display: block;
	float:left; 
	width: 80px;
	height: 80px;
	background-color: #52A2DA;
}
.event-border-box {
	height: 70px;
	border: 1px solid #FFFFFF;
}
.event-nav-button {
	/* width: 130px !important; */
	min-width: 125px;
	background-color: #363636;
}
.event-nav-button a {
	text-decoration: none;
}
.event-nav-button:hover {
	background-color: #0373BB;
}
.event-nav-button:hover a {
	color: #FFFFFF;
	text-decoration: none;
}
.events-map-container .event-map {
	display: block;
   width: 100%;
   height: 400px;
   background-color: grey;
}
.event-paging-bar-background {
	background-color: #52A2DA;
}
.event-paging-bar-nav-background {
	background-color: #4D7B9C;
}

/* end of event page styles */

.top-headlines-droplogo img {
	width: 80px;
}

.dropbtn {
    border: none;
    cursor: pointer;
}

 .dropbtn:hover, .dropbtn:focus {
 	filter: contrast(50%); 
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: -20px;
    background-color: #EFEFEF;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-content a img {
	width: 120px;
	filter: saturate(0%);
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover img {
	filter: saturate(100%);
}

.show {display:block;}

.newspaper-dropdown-content {
	display: none;
    position: absolute;
    top: -20px;
    background-color: #EFEFEF;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.newspaper-dropdown-content a img {
	width: 120px;
	filter: saturate(0%);
    text-decoration: none;
    display: block;
}
.newspaper-dropdown-content a:hover img {
	filter: saturate(100%);
}





















/* main block general styles */
.center-section-block-title a {
	font-size: 12pt;
	color: #000000;
	display: inline-block;
	line-height: 16px;
}
.center-section-block-title-white a {
	font-size: 12pt;
	color: #FFFFFF;
	font-weight: bold;
	display: inline-block;
	line-height: 16px;
}
/* end of main block general styles */


/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #363636; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
/* .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 12pt;
    color: #FFFFFF;
    display: block;
    transition: 0.3s
} */
/* .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s
} */

/* When you mouse over the navigation links, change their color */
.sidenav a:hover, .offcanvas a:focus{
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
	font-family: 'Montserrat', Arial, sans-serif;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 11pt;
    margin-left: 50px;
    padding-top: 15px;
    color: #FFFFFF;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}





/* Remove margins and padding from the list, and add a black background color */
ul.topnav {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #333;
}

/* Float the list items side by side */
ul.topnav li {
	float: left;
}

/* Style the links inside the list items */
ul.topnav li a {
	display: inline-block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	transition: 0.3s;
	font-size: 17px;
}

/* Change background color of links on hover */
ul.topnav li a:hover {
	background-color: #555;
}

/* Hide the list item that contains the link that should open and close the topnav on small screens */
ul.topnav li.icon {
	display: none;
}


/* Desktop side menu paper publications select styles */
.side-paper-select {
	padding-left: 0;
}
.side-paper-select li:nth-child(1) {
	padding: 15px 0 8px 0;
	border-bottom: 1px solid #737373;
}
.side-paper-select li:nth-child(n+2) {
	padding: 8px 0;
	border-bottom: 1px solid #737373;
}
.dropdown-content-side {
	display: none;
	position: absolute;
	top: 170px;
	min-width: 160px;
	background-color: #363636;
}
/* End of Desktop side menu paper publications select styles */

#newsDropdown {
	display: none;
}
#sportsDropdown {
	display: none;
}
#featuresDropdown {
	display: none;
}


/* When the screen is less than 680 pixels wide, hide all list items, except for the first one ("Home"). Show the list item that contains the link to open and close the topnav (li.icon) */
@media screen and (max-width:992px) {
	/* ul.topnav li:not (:first-child ) {
		display: none;
	} */
	.mobile-header-logo {
		/* position: absolute; */
		margin-left: -100px;
		left: 50%;
	}
	.mobile-header-logo a {
		padding-top: 0 !important;
	}
	.carousel-inner .caption {
		height: 70px;
	}
	.carousel-inner .caption p {
		padding-right: 10px;
		line-height: 1;
	}
	ul.topnav li {
		display: none;
	}
	ul.topnav li.icon {
		float: left;
		display: inline-block;
		width: 50px;
	}
		ul.topnav.responsive {
		position: relative;
	}
	ul.topnav.responsive li.icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	ul.topnav.responsive li {
		float: none;
		display: inline;
	}
	ul.topnav.responsive li a {
		display: block;
		text-align: left;
	}
	h3 {
		font-size: 16pt;
	}
	.main-content-container {
		display: block !important;
		position: initial !important;
	}
	.right-content-container {
		display: block !important;
		position: initial !important;
		float: none;
		margin: 0 auto !important;
		padding: 0 15px !important;
		max-width: 100% !important;	
	}
	.upcoming-events-container {
		margin-bottom: 50px;
	}
	.upcoming-events-container  .text-md-float-right {
		width: 50% !important;
	}
	.event-nav-button {
		margin: 5px 8px !important;
		width: 100% !important;
	}
	.footer-logos {
		text-align: center;
	}
	.main-footer-logo {
		padding-bottom: 30px;
	}
	.article-standard-max-width {
		float: none !important;
		margin: 0 auto;
		padding-bottom: 15px;
	}
	.mobile-menu-collapse {
		display: none;
	}
	.mobile-menu-handler {
		position: relative;
		margin-top: -20px;
		margin-right: 5px;
		cursor: pointer;
		/* right: -73%; */
		top: 20px;
	}
}
/* @media screen and (max-width:320px) and (max-width:399px) {
	.mobile-header-logo {
		left: 25% !important;
	}
}
@media screen and (max-width:400px) and (max-width:399px) {
	.mobile-header-logo {
		left: 36% !important;
	}
} */
/* @media screen and (max-width:680px) {
	ul.topnav li:not (:first-child ) {
		display: none;
	}
	ul.topnav li {
		display: none;
	}
	ul.topnav li.icon {
		float: left;
		display: inline-block;
	}
} */

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens */
/* @media screen and (max-width:680px) {
	ul.topnav.responsive {
		position: relative;
	}
	ul.topnav.responsive li.icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	ul.topnav.responsive li {
		float: none;
		display: inline;
	}
	ul.topnav.responsive li a {
		display: block;
		text-align: left;
	}
} */

/* styles for shop content */
/*end of styles for shop content*/
/* .shop-left-menu {
	display: block;
	margin-bottom: -99999px;
	padding-bottom: 99999px;
	position: relative;
	z-index: 0;
} */



/*scoreboard header nav button styles*/
body div.main-center div.section div.node .header-score-buttons {
	margin: 10px 15px;
	display: inline-block;
	border-radius: 2px;
	border: 2px solid #0073BA;
	background: linear-gradient(to bottom, #9c9c9d 0%,#616264 100%);
	color: #FFFFFF;
	font-weight: bold;
	width: 150px;
	height: 20px;
	text-align: center;
	padding-top: 2px;
	text-decoration: none;
}

/*scoreboard content styles */

#node-scoreboard-content {
/* 	display: table-cell; */
}

#node-scoreboard-content h1 {
	text-transform: uppercase;
	font-family: "Roboto Slab", Arial, serif;
	font-size: 20px;
	width: 50%;
}

#node-scoreboard-content h2 {
	background: #000000;
	height: 30px;
	color: #FFFFFF;
	text-transform: uppercase;
	font-family: "Roboto Slab", Arial, serif;
	font-size: 14px;
	width: 50%;
	margin: 0;
}

#node-scoreboard-content p {
	width: 50%;
	font-style: italic;
	font-family: "Roboto Slab", Arial, serif;
}

#node-scoreboard-content table {
	margin-top: -10px;
	border: 1px solid #888888;
	width: 100%;
	background-color: #D7D7D7;
}

#node-scoreboard-content table td {
	padding: 7px;
	v-align: top;
}

#node-scoreboard-content table tr.border-score {
	border-bottom: 3px solid #FFFFFF;
}

#node-scoreboard-content .schoolName {
	width: 250px;
}

#node-scoreboard-content .schoolScore {
	text-align: right;
	width: 50px;
}

#node-scoreboard-content .storyLink {
	white-space: nowrap;
	width: 50px;
	text-align: right;
}

#node-scoreboard-content .storyLink a {
	background: #1e5799; /* Old browsers */
	background: -moz-linear-gradient(top,  #1e5799 0%, #7db9e8 70%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1e5799), color-stop(70%,#7db9e8)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #1e5799 0%,#7db9e8 70%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #1e5799 0%,#7db9e8 70%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #1e5799 0%,#7db9e8 70%); /* IE10+ */
	background: linear-gradient(to bottom,  #1e5799 0%,#7db9e8 70%); /* W3C */
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	color: #FFFFFF;
	padding: 3px 5px;
	text-decoration: none;
}

#node-scoreboard-content table tr:nth-child(odd) {
 	background-color: #F2F2F2; 
}

#node-scoreboard-content .scoreboardWinner {
	font-weight: bold;
	color: #00007A;
}

.scoreboard-title {
	letter-spacing: 1px;
	word-spacing: 3px;
	font-size: 16px;
	color: #FFF;
	padding-top: 78px;
	font-weight: bold;
	text-align: center;
	border-bottom: none;
}

/*scoreboard previous posted links */
div.scoreboard-links .scoreboard-previous-title {
	padding-top: 15px;
	font-weight: bold;
	color: #000000;
	font-size: 18px;
}
div.scoreboard-links p a {
	line-height: 50%;
	color: #00007A;
}
/*end of scoreboard posted links styles */


div.scoreboard-content-container {
	/* display: table; */
	padding-bottom: 10px;
	border-bottom: 2px solid #B7B7B7;
}

div#scoreboard-content {
	width: 270px;
	padding-left: 5px;
}

div#scoreboard-content table {
	width: 95%;
}

div#scoreboard-content table td.scoreboard-game-title {
	text-align: center;
	margin: 0;
	font-family: 'Montserrat', Arial, sans-serif;
	text-transform: uppercase;
	color: #0073BB;
	padding-bottom: 10px;
}

div#scoreboard-content div.slidesjs-container {
	height: 120px !important;
}

div.scoreboard-content-container div.header-scoreboard-image {
	display: block;
	float: left;
	clear: both;
}

div.scoreboard-content-container div.header-scoreboard-image img {
	padding-right: 10px;
}

div.scoreboard-content-container div.header-scoreboard-image a span {
	font-family: 'Montserrat', Arial, sans-serif;
	text-transform: uppercase;
	color: #000000;
}

div.scoreboard-content-container div.header-scoreboard-data {
	display: inline-block;
	width: 270px;
	padding-top: 10px;
	margin-top: 0;
	height: 40px;
	text-align: left;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr td {
	padding: 3px;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr.home-team {
	border-bottom: 1px solid #888;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr.home-team, tr.away-team {
	color: #000000;
	text-transform: none;
	font-weight: normal;
	padding: 0 5px;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr.winner {
	font-weight: bold;
} 

div.scoreboard-content-container div.header-scoreboard-data div table tr td.scoreboard-more-link span.scoreboard-more-date {
	display: inline-block;
	font-style: italic;
	font-weight: normal;
	padding-left: 100px;
	font-size: 12px;
} 

div.scoreboard-content-container div.header-scoreboard-data div table tr td.scoreboard-more-link {
	font-size: 12px;
	font-weight: bold;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr td.scoreboard-more-link a {
	display: inline-block;
	width: 120px;
}

div.scoreboard-content-container div.header-scoreboard-data div table tr.home-team td.winnerBack {
	background-color: #000000;
	color: #FFFFFF;
}
div.scoreboard-content-container div.header-scoreboard-data div table tr.away-team td.winnerBack {
	background-color: #000000;
	color: #FFFFFF;
}

div.scoreboard-content-container div.header-scoreboard-data ul li p.winner {
	font-weight: bold;
	margin: 0;
}

#scoreboard-content {
	display: inline-block;
	/* padding-left: 20px;
	width: 500px;
	margin: 0;
	padding-top: 5px; */
}

#scoreboard-content ul.slidesjs-pagination {
	display: none;
}

#scoreboard-content a.slidesjs-previous {
	display: none;
}

#scoreboard-content a.slidesjs-next {
	display: none;
} 

ul.scoreboard-nav-wrapper {
	display: table;
	/* padding-top: 20px; */
	/* padding-right: 15px; */
	margin-top: 0;
	padding-left: 0;
	margin-bottom: 0;
	/* float: right; */
	position: relative;
	/* left: 550px;
	bottom: 25px; */
	bottom: 95px;
	left: -15px;
	width: 320px;
	list-style: outside none none;
}

ul.scoreboard-nav-wrapper li {
	display: inline-block !important;
	background-color: #f4f4f4;
	
}

ul.scoreboard-nav-wrapper li a img {
	vertical-align: middle;
	margin: 20px 2px 20px 2px;
}

ul.scoreboard-nav-wrapper li:nth-child(2) {
	margin-left: 272px;
}

div.scoreboard-ad {
	display: inline-block;
	position: relative;
 	left: 25px; 
}

input#edit-field-scoreboard-widget-0-value {
	display: none !important;
}

[for="edit-field-scoreboard-widget-0-value"] {
	display: none !important;
}

[for="event_category"] {
	display: block;
	margin-bottom: 20px;
	font-size: 20px;
}

input#event-title-value {
	display: block;
	width: 400px;
	margin-bottom: 15px;
}

input#home-name-value {
	display: block;
	width: 200px;
	margin-bottom: 15px;
}

input#home-score-value {
	display: block;
	width: 130px;
	margin-bottom: 15px;
}

input#away-name-value {
	display: block;
	width: 200px;
	margin-bottom: 15px;
}

input#away-score-value {
	display: block;
	width: 130px;
}

input.score-save {
	display: block;
	margin-top: 15px;
}

div.scoreboard-events-container .scoreboard-events-title {
	font-size: 18px;
}

div.scoreboard-events-container table {
	display: table;
	width: 100%;
}

div.scoreboard-events-container .row-button {
	margin-bottom: 10px;
}

div.scoreboard-events-container table tbody tr input.event-title {
	width: 300px;
}
div.scoreboard-events-container table tbody tr input.home-score {
	width: 100px;
}
div.scoreboard-events-container table tbody tr input.away-score {
	width: 100px;
}
div.scoreboard-events-container table tbody tr input.event-date {
	width: 100px;
}

div.field-field-scoreboard-widget {
	display: none;
}






.slides {
	position: relative;
	overflow: hidden;
	max-height: 600px !important;
}

.slides li {
	max-height: 600px !important;
	background-color: #000000;
}

.slides li img {
	text-align: center;
	width: auto !important;
	max-height: 600px !important;
}

/* #slider {
  position: relative;
  overflow: hidden;
  margin: 20px auto 0 auto;
  border-radius: 4px;
}

#slider ul {
  position: relative;
  margin: 0;
  padding: 0;
  height: 200px;
  list-style: none;
}

#slider ul li {
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 600px;
  width: 500px;
  height: 300px;
  background: #ccc;
  text-align: center;
  line-height: 300px;
}

a.control_prev, a.control_next {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 4% 3%;
  width: auto;
  height: auto;
  background: #2a2a2a;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
}

a.control_prev:hover, a.control_next:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
}

a.control_prev {
  border-radius: 0 2px 2px 0;
}

a.control_next {
  right: 0;
  border-radius: 2px 0 0 2px;
}

.slider_option {
  position: relative;
  margin: 10px auto;
  width: 160px;
  font-size: 18px;
} */
/* #slider {
  position: relative;
  overflow: hidden;
  margin: 20px auto 0 auto;
  border-radius: 4px;
}

#slider ul {
  position: relative;
  margin: 0;
  padding: 0;
  height: 200px;
  list-style: none;
}

#slider ul li {
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 500px;
  height: 300px;
  background: #ccc;
  text-align: center;
  line-height: 300px;
}

a.control_prev, a.control_next {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 4% 3%;
  width: auto;
  height: auto;
  background: #2a2a2a;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
}

a.control_prev:hover, a.control_next:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
}

a.control_prev {
  border-radius: 0 2px 2px 0;
}

a.control_next {
  right: 0;
  border-radius: 2px 0 0 2px;
}

.slider_option {
  position: relative;
  margin: 10px auto;
  width: 160px;
  font-size: 18px;
} */





/* .slide-container {
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  min-height: 600px;
}

.slider {
  overflow: hidden;
  height: 600px;
  position: relative;
}

.slider ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.slider li {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
}

.slider li img {
  border: none;
  width: 100%;
  min-height: 100%;
}

.slider button {
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  top: 0;
  bottom: 0;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  margin: 0;
  padding: 0;
  text-align:center;
  opacity: 0;
  z-index: 2;
}

.slider button.prev {
  left: 0;
}

.slider button.next {
  right: 0;
}

.slider button:hover, .slider button:active {
  opacity: 1.0;
}

.slider .content {
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3em;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 3em;
  padding: 0 1em;
  font-size: 1.5em;
}

.slider .content a {
  color: inherit;
}

.slide-cutline {
	display: block;
	width: 75%;
} */

/* weekly publications styles */
.weekly-publications-container {
	display: block;
	/* margin-left: 30px; */
	border: 2px solid #D1D3D4;
	max-width: 300px;
	margin-bottom: 15px;
	background-color: #FFFFFF;
}
.weekly-publications-container a.weekly-publications-link {
	display: block;
	margin-bottom: 15px;
	text-align: center;
	color: #FFFFFF;
	padding: 8px 0;
	background-color: #252525;
}
.weekly-publications-container a img {
	margin: 0 auto;
	text-align: center;
}
/* end of weekly publications styles */
.right-content-container {
	width: 300px !important;	
	padding: 0;
}
.trending-container {
	max-width: 300px !important;
}
.upcoming-events-container {
	/* max-width: 300px !important; */
}
.scoreboard-content-container {
	max-width: 300px;
}
.gallery-image-container {
	max-height: 105px;
	overflow: hidden;
}
.gallery-article-image-container {
	position: relative;
	max-height: 180px;
	overflow: hidden;
}
.article-related-gallery-icon {
	position: absolute;
	top: 8px;
	width: 50px;
	left: 10px;
}
@media (max-width: 768px) {
	.gallery-block-img {
		width: 100% !important;
		height: 100px !important;
		overflow: hidden;
	}
	.listing-small-text-container {
		display: block !important;
		width: 100% !important;
	}
	.listing-small-image-container {
		float: none !important;
	}
	.upcoming-events-container .text-md-float-right {
		width: 80% !important;
	}	
	.newspaper-select-logo {
		text-align: center !important;
		margins: 0 auto;
	}
	.right-content-container {
		display: block !important;
		position: initial !important;
		float: initial !important;
		margin: 0 auto !important;
		padding: 0 15px !important;
		max-width: 100% !important;	
	}
	.digital-section-slider > img.slick-next {
		display: block !important;
	}
	.digital-section-slider > img.slick-prev {
		display: block !important;
	}
}
@media (max-width: 992px) {
	.gallery-block-img {
		height: 90px !important;
		width: 100%;
		overflow: hidden;
	}
}
@media (max-width: 478px) {
	
}
/* @media (min-width: 992px) {
	
	.upcoming-events-container {
		width: 95% !important;
		padding-left: 30px;
	}
	.trending-container {
		width: 92% !important;
		padding-left: 30px;
	}
	.scoreboard-content-container {
		width: 92% !important;
		padding-left: 30px;
		margin-top: 30px;
	}
} */

p.listings-large-header {
	font-weight: bold;
	font-family: Arial, sans-serif;
	font-size: 18px;
	margin-bottom: 5px;
}

.listings-sponsored-container {
	display: table;
	background-color: #F8F8F8;
	width: 100%;
}

.listings-sponsored-container p.sponsored-title {
	text-transform: uppercase;
	font-family: Arial, sans-serif;
	color: #939598;
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	margin-bottom: 0;
}

.listing-random-container {
	display: table;
	width: 95%;
	margin-left: 15px;
}

.random-content {
	display: inline-table;
	width: 120px;
	vertical-align: top;
	margin: 0 10px;
}

.random-content p.random-image {
	margin-bottom: 5px;
}

.random-content p.random-title {
	font-size: 11px;
	margin-top: 0;
	line-height: 1.1;
}

.random-content p.random-title a {
	color: #000000;
	font-weight: bold;
}

.listings-large-container {
	display: table;
}

.listings-large-container p.listing-large-title {
	font-family: Arial, sans-serif;
	font-size: 2em;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 0;
}

.listings-large-container p.listing-large-title a {
	text-decoration: none;
	color: #000000;
}

.listings-large-container .large-category-box {
	display: block;
	width: 180px;
}

.listings-large-container p.listing-large-category {
	display: inline;
	padding: 5px 8px;
	color: #FFFFFF;
	text-align: center;
	font-family: 'Montserrat', Arial, sans-serif;
	text-transform: uppercase;
	font-weight: bold;
	font-size: .9em;
	margin-bottom: 0;
}

.listings-large-container .listing-large-body {
	display: block;
}

.listings-large-container .listing-large-body p {
	text-align: left;
	font-size: 15px;
	font-family: 'Georgia', Arial, sans-serif;
	line-height: 1.3;
	margin-top: 0;
}

p.listings-multi-header {
	display: block;
	text-transform: uppercase;
	font-family: 'Montserrat', Arial, sans-serif;
	font-weight: bold;
	font-size: 14px;
	margin: 30px 0;
}

.listings-multi-container {
	/* display: table; */
	padding: 15px 0;
	border-bottom: 1px solid #808285;
}

.listings-multi-container .category-box {
	display: block;
	width: 180px;
}

.listings-multi-container p.listing-small-category {
	display: inline;
	padding: 5px 8px;
	color: #FFFFFF;
	text-align: center;
	font-family: 'Montserrat', Arial, sans-serif;
	text-transform: uppercase;
	font-weight: bold;
	font-size: .9em;
	margin-bottom: 0;
}

.listings-multi-container p.listing-small-title {
	font-family: Arial, sans-serif;
	font-size: 1.5em;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 0;
	line-height: 1.2;
}

.listings-multi-container p.listing-small-title a {
	text-decoration: none;
	color: #000000;
}

.listings-multi-container p.listing-small-sponsored {
	font-family: Arial, sans-serif;
	text-transform: uppercase;
	color: #5D5D5D;
	font-size: 12px;
}

p.listing-small-sponsored {
	font-family: Arial, sans-serif;
	text-transform: uppercase;
	color: #5D5D5D;
	font-size: 12px;
}

.listings-multi-container p.listing-small-sponsored img {
	padding-bottom: 2px;
}

.listings-multi-container .listing-small-text-container {
	display: inline-block;
	/* width: 380px; */
	width: 75%;
	margin-right: 30px;
}

.listings-multi-container .listing-small-image-container {
	dislay: block;
	float: right;
	clear: both;
/* 	height: 120px;
	overflow: hidden; */
}

.listing-search-container {
	display: block;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

p.business-search-back {
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 14px;
	font-weight: bold;
	text-transform: none;
	background-color: #636363;
	cursor: pointer;
	padding: 5px;
	width: 133px;
	margin-bottom: 50px;
}

p.business-search-back a {
	color: #FFFFFF;
	text-decoration: none;
	text-transform: uppercase;
}

p.search-title {
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid #000000;
	padding-bottom: 5px;
}




/* end of business profiles styles */

/* related stories styles */
div.related-stories-container {
	display: table;
	width: 300px;
}
div.related-stories-container p.related-stories-header {
	background-color: #898989;
	width: 98%;
	color: #FFFFFF;
	font-weight: bold;
	padding: 5px 0 5px 5px;
}

div.related-stories-container div.related-stories {
	display: table;
	border-bottom: 1px solid #898989;
	width: 100%;
	margin-top: 10px;
}

div.related-stories-container div.related-stories p.related-image {
	display: block;
	float: left;
	clear: both;
	padding: 0 15px 15px 0;
/* 	width: 82px;
	height: 80px;
	overflow: hidden; */
	margin: 0;
}

div.related-stories-container div.related-stories p.related-text {
	display: inline-block;
	width: 180px;
	margin: 0;
	line-height: 1.3;
	padding-top: 0;
	padding-bottom: 15px;
	text-align: left;
}

p.full-related {
	width: 100% !important;
}

div.related-stories-container div.related-stories p.related-text a {
	font-size: 10pt;
	font-weight: bold;
	color: #024C91;
}
/* end of related stories styles */

/* headline slideshow styles */
.headline-slideshow-container {
	display: table;
}
#headline-left-box > div {
	display: none;
}
#headline-left-box > div.default {
	display: table;
}

#headline-left-box > div.active {
	display: table;
}
/* #carousel-custom {
    margin: 20px auto;
    width: 100%;
}
#carousel-custom .carousel-indicators {
    margin: 10px 10px 0;
    overflow: auto;
    overflow-x: hidden;
    position: static;
    text-align: left;
    white-space: nowrap;
    width: 97%;
    padding-left: 5px;
}
#carousel-custom .carousel-indicators li {
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    height: auto;
    margin: 0 !important;
    width: 120px;
}
#carousel-custom .carousel-indicators li img {
    display: block;
    opacity: 0.5;
}
#carousel-custom .carousel-indicators li.active img {
    opacity: 1;
}
#carousel-custom .carousel-indicators li:hover img {
    opacity: 0.75;
}
#carousel-custom .carousel-outer {
    position: relative;
    background: #000000;
}
.carousel-control {
	position: absolute;
	background: none !important;
}
.headline-arrow-left {
	position: absolute;
	left: 20px;
	top: 50%;
}
.headline-arrow-right {
	position: absolute;
	right: 20px;
	top: 50%;
}
.headline-img {
	position: relative;
	margin: 0 auto;
}
.caption {
	position: absolute;
	background-color: #000000;
	bottom: 0;
	width: 100%;
	height: 40px;
	color: #FFFFFF;
	opacity: 0.8;
}
.arrow-left, .arrow-right {
	display: block;
	position: absolute;
	cursor: pointer;
}
.arrow-left {
	left: 0px;
	margin-top: 11px;
	background-color: #f0f0f0;
	height: 118px;
}
.arrow-left .fa-arrow-left {
	margin: 50px 0px;
}
.arrow-right .fa-arrow-right {
	margin: 50px 0px;
}
.arrow-right {
	right: 0px;
	margin-top: 11px;
	background-color: #f0f0f0;
	height: 118px;
} */
/* end of headline slideshow styles */
.twitter-typeahead {
	display: block !important;
}
.tt-dataset-gallery {
	margin-top: -10px;
	background-color: #FFFFFF;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.tt-selectable {
	padding: 0 8px;
	cursor: pointer;
}
.tt-selectable:hover {
	color: #FFFFFF;
	background-color: #2A6496;
}

/* special digital section page styles */
.slick-prev {
	left: 0 !important;
	z-index: 10 !important;
	width: 50px !important;
	height: 99px !important;
}
.slick-next {
	right: 0 !important;
	z-index: 10 !important;
	width: 50px !important;
	height: 99px !important;
}
.slick-next:hover {
	 -webkit-filter: contrast(500%); /* Safari */
    filter: contrast(500%);
}
.slick-prev:hover {
	-webkit-filter: contrast(500%); /* Safari */
    filter: contrast(500%);
}
.digital-section-slider > img.slick-next {
	display: none;
}
.digital-section-slider > img.slick-prev {
	display: none;
}
/* end of special digital section page styles */
/* sds advertisers block styles */
.sds-advertisers-block {
	display: block;
	background-color: #FFFFFF;
}
.sds-advertisers-block > h3 {
	background-color: #0373BA;
	color: #FFFFFF;
}
/* end of sds advertisers block styles */